* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  background-color: #f8fbff !important;
  color: #7e7e7e !important;
}

html {
  scroll-behavior: smooth;
  font-size: 34%;
}

a,
button,
form,
select,
input {
  outline: none;
}

body,
input,
textarea,
span,
a,
p,
li,
button {
  font-family: Roboto, Arial, Helvetica, sans-serif !important;
}

button,
a {
  cursor: pointer;
  text-decoration: none;
  border: 0;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--title-color);
  font-weight: 300;
  font-family: Roboto !important;
}

/* Extra small devices (portrait phones, less than 576px)
   No media query since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  html {
    font-size: 42%;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  html {
    font-size: 50%;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  html {
    font-size: 62.5%;
    /*1rem = 10px */
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  html {
    font-size: 68%;
  }
}

/* usar tudo em rem */
